import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { DonationPeriodicityTypes } from "src/app/models/donations/donationsStatusRes.models";

export const storeDonationsPeriodicityTypes = createAction(
    '[Donations Periodicity] store donations periodicity',
    props<{ order : string }>()
)

export const storeDonationsPeriodicityTypesSuccess = createAction(
    '[Donations Periodicity] store donations periodicity success',
    props<{ donationsPeriodicityTypes : DonationPeriodicityTypes[] }>()
)

export const storeDonationsPeriodicityTypesFailure = createAction(
    '[Donations Periodicity] store donations periodicity failure',
    props<{ error : HttpErrorResponse }>()
)