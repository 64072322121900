import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import { DonationStatusTypes } from 'src/app/models';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationsStatusEffects {

    constructor( 
        private action$ :                                        Actions,
        private donationsService:                                DonationsService,
    ){}

    loadDonationStatusCodes$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.storeDonationsStatusTypes),
            switchMap(() =>
                {
                    return this.donationsService.LoadDonationsStatus( )
                    .pipe(
                        map(( res : object ) => {

                            const donationsStatusTypes : DonationStatusTypes[] = [];

                            const statusKeys = Object.keys( res );

                            const statusValues = Object.values( res );

                            for (let i = 0; i < statusKeys.length; i++) {
                                
                                donationsStatusTypes.push({ type : statusKeys[i] , num : statusValues[i] })
                            }

                            return actions.storeDonationsStatusTypesSuccess ({ donationsStatusTypes });
                        }),
                        catchError(error => {

                            return of( actions.storeDonationsStatusTypesFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}