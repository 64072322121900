export class DonationStatusTypes{

    type =                                       "";
    num  =                                       0;
}

export class DonationPeriodicityTypes{

    type =                                       "";
    num  =                                       0;
}