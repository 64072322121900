import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FormatDatesService {

  FormatDateForTable(dateString: string): string {
    
    const date = new Date(dateString);
    
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  AddDaysToADate( date : string , days : number):string{

    const d = new Date( date );
    d.setDate(d.getDate() + days); 
    return d.toISOString().split("T")[0];
  }

  GetFullTime( date : string ):string{

    const myDate = new Date( date );

    const hours = myDate.getHours().toString().padStart(2, "0");
    const minutes = myDate.getMinutes().toString().padStart(2, "0");
    const seconds = myDate.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
}
