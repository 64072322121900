import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationsForSepaEffects {

    constructor( 
        private action$ :                   Actions,
        private donationsService:           DonationsService
    ){}

    loadDonations$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( actions.getDonationsSelectedForSepa ),
            switchMap(action =>
                {
                    const { donationsForSepaFilters } = action ||{};
                    
                    return this.donationsService.LoadDonationsFiltered( donationsForSepaFilters )
                    .pipe(
                        map(donationsRes => {

                            return actions.getDonationsSelectedForSepaSuccess ({donationsRes});
                        }),
                        catchError(error => {
                            return of( actions.getDonationsSelectedForSepaFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })
}