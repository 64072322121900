import { createReducer, on } from "@ngrx/store";
import { Sepa, SepaRes } from "src/app/models";
import * as actions from 'src/app/store/actions';

export interface sepaState {
    SepasRes : SepaRes
}

export const initialUserEntries : SepaRes = new SepaRes() ;

export const SepasReducer = createReducer(

    initialUserEntries,

    on( actions.getSepasSuccess , (state , { sepaResponse }) => {

        state = { ...sepaResponse , stateStatus : 'sepas_assignement' };

        return state;
    }),

    on( actions.addSasUriToSepaSuccess , (state , { sepaSasUri }) => {

        const { sepaId , sasUri } = sepaSasUri || {};

        const mySepas = [ ...state.items ].map(( s : Sepa ) => {

            return s.id === sepaId ? { ...s , sasUri : sasUri } : { ...s };
        })

        return { ...state , items : mySepas };
    }),

    on( actions.storeSepaSuccess , ( state , { sepa } ) =>{

        const mySepas = [ ...state.items ];

        mySepas.push( sepa );

        return { ...state , items : mySepas };
    }), 

    on( actions.setSepaAsSelected , ( state , { sepa } ) => {

        const { id } = sepa || {};

        return { ...state , items : [ ...state.items ].map(( s : Sepa ) => { return s.id === id ? { ...s , selected : true } : { ...s } })}
    }),

    on( actions.setSepaAsUnSelected , ( state , { sepa } ) => {

        const { id } = sepa || {};

        return { ...state , items : [ ...state.items ].map(( s : Sepa ) => { return s.id === id ? { ...s , selected : false } : { ...s } })}
    }),

    on( actions.setSepaAsSentToBankSuccess , ( state , { response , sepasIds } ) => {

        const { isSuccess } = response || {};

        if( isSuccess ){

            return { ...state , items : [ ...state.items ].map(( s : Sepa ) => {

                const { id } = s || {};

                if( sepasIds.find(( sepaId : string ) => sepaId === id ) ){

                    return { ...s , sentToBank : true , selected : false };
                
                } else {

                    return { ...s };
                }

            })}
        } else {

            return state;
        }
    })
) 