const getProperDEVEnvironementUrl = () => {

    const originalUrl =  window.location.href

    if(originalUrl.includes("prerelease") ){

        return 'https://orange-water-0be235f03-prerelease.westeurope.4.azurestaticapps.net';

    } else if (originalUrl.includes("localhost")){

        return 'http://localhost:4202';
    
    } else {

        return 'https://orange-water-0be235f03.4.azurestaticapps.net/';
    }
}

export const environment = {

    production : false,

    environmentProtocol:'https://',
    
    environmentDomain: window.location.hostname === 'localhost' ? 'http://localhost:' : '-dev.clinicabaviera.com',

    donationsService : {
        
        url: /* window.location.hostname === 'localhost' ? 'http://localhost:5143/api/' : */ 'https://app-donations-dev-westeurope.azurewebsites.net/api/' ,
        clientId: 'c6baa32a-d8d2-4ff1-8afe-f19552f8e224', 
        authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',
        
        
        redirectUri: /* getProperDEVEnvironementUrl() */ window.location.hostname === 'localhost' ? 'http://localhost:4202' : 'https://donations-dev.clinicabaviera.com',

        logoutRedirectUri: window.location.hostname === 'localhost' ? 'http://localhost:4202' : 'https://donations-dev.clinicabaviera.com', 
        scopes:[
            'profile',
            'openid' , 
            'c6baa32a-d8d2-4ff1-8afe-f19552f8e224/donations'
        ],
    },

    usersService : {
        url:'https://app-users-dev-westeu.azurewebsites.net/api/',  
        scopes:[
            '05a35cc1-faee-44e0-b1e4-0cff3f341f0d/users',
            'profile',
            'openid' 
        ],
        clientId:'05a35cc1-faee-44e0-b1e4-0cff3f341f0d',  
    },
};


