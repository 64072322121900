<div *ngIf="loginDisplay" class="auth_main-container" >

    <div class="auth_main-login_logout_container" >

        <div class="auth_main-doctors_photo_container">

            <img 
                *ngIf="avatar" 
                class="auth_main-doctors_photo_ngSrcimg"
                [ngSrc]="avatar || 'https://google.com'" 
                width="30"
                height="30" 
                alt="foto del doctor"
            >

            <img  
                *ngIf="!avatar" 
                class="auth_main-doctors_photo_img" 
                src="../../assets/logos/default_avatar.jpg" 
                alt="foto del doctor"
            >

        </div>

        <div class="auth_main-bienvenido_container" >
            <span class="auth_main-bienvenido_span" >{{user_name}}</span>
        </div>

        <div  (click)="Logout()" class="auth_main-container_logout_container" >
            <fa-icon [icon]="faLock"></fa-icon>
            <span  >Logout</span>
        </div>
    </div>
</div>