import { DonationsFilters } from '../../../models';
import { createReducer , on } from '@ngrx/store';
import * as actions from 'src/app/store/actions';

export interface donationsFiltersState {
    DonationsFilters : DonationsFilters
}

export const initialUserEntries : DonationsFilters = new DonationsFilters() ;

export const DonationsFiltersReducer = createReducer(
    
    initialUserEntries,

    on(actions.modifyDonationsStatusfilter , (state , {donationsStatusFilter})=>{

        const associateds : boolean = donationsStatusFilter === 'asociated';
        
        const specifics : boolean = donationsStatusFilter === 'specific';

        return { ...state , associated : associateds , specific : specifics , first:1 , size:10 , filterStatus : 'real_assignement' };
    }) , 

    on( actions.modifyDonationsfilter , (state , { donationsFilters }) => {

        const { email , firstName , fiscalAddress , documentNumber , accountNumber , first , size , from , to } = donationsFilters || {};

        return { 
            ...state , 
            email : email , 
            from : from ,
            to : to,
            firstName : firstName ,  
            fiscalAddress : fiscalAddress , 
            documentNumber : documentNumber , 
            accountNumber : accountNumber, 
            first: first,
            size : size,
            filterStatus : 'real_assignement'
        };

    }),

    on( actions.addUserEmailToFilters , ( state , { userName } ) => {
        return {...state , email : userName}
    }),

    on( actions.modifySizeDonationFilter , (state , {size}) => {

        return { ...state , size : size };
    }),

    on( actions.modifyFirstDonationFilter , (state , { first }) =>{

        return { ...state , first : first };
    })
) 