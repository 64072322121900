import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { hydrationMetaReducer } from "./hydratation.reducer";
import { AppState } from "src/app/models";
import { UserReducer } from "./reducers/users/user.reducers";
import { DonationsFiltersReducer } from "./reducers/donations/donationsFilters.reducers";
import { ProfessionalsJsonReducer } from "./reducers/users/users.reducers";
import { DonationsFilteredReducer } from "./reducers/donations/donationsFiltered.reducers";
import { DonationsSelectedReducer } from "./reducers/donations/donationsToSendByEmail.reducers";
import { DonationProjectsReducer } from "./reducers/donations/donationProjects.reducers";
import { DonationAuditLogsReducer } from "./reducers/donations/donationAuditLogs.reducers";
import { DonationAuditLogsFilterReducer } from "./reducers/donations/donationAuditLogsFilters.reducers";
import { DonationsForSepaFiltersReducer } from "./reducers/sepas/donations-sepa-filters.reducers";
import { DonationsForSepaReducer } from "./reducers/sepas/donations-sepa-selected.reducers";
import { SepasReducer } from "./reducers/sepas/sepas.reducers";
import { SepasFiltersReducer } from "./reducers/sepas/sepasFilters.reducers";
import { DonationsInSepaReducer } from "./reducers/donations/donationsInSepa.reducers";
import { BankCodesReducer } from "./reducers/donations/bankCodes.reducers";
import { DonationsStatusTypesReducer } from "./reducers/donations/donation-status-types.reducers";
import { DonationsPeriodicityTypesReducer } from "./reducers/donations/donations-periodicity-types.reducers";

export const reducers : ActionReducerMap<AppState> = {
  User :                                    UserReducer,
  DonationsFilters :                        DonationsFiltersReducer,
  professionalsJSON:                        ProfessionalsJsonReducer,
  DonationsFiltered:                        DonationsFilteredReducer,
  DonationsSelected:                        DonationsSelectedReducer,
  DonationProjects:                         DonationProjectsReducer,
  DonationAuditLogs :                       DonationAuditLogsReducer,
  DonationAuditLogsfilters:                 DonationAuditLogsFilterReducer,
  DonationsForSepaFilters:                  DonationsForSepaFiltersReducer,
  DonationsForSepaSelected:                 DonationsForSepaReducer,
  Sepas:                                    SepasReducer,
  SepasFilters:                             SepasFiltersReducer,
  DonationsInSepa:                          DonationsInSepaReducer,
  BankCodes:                                BankCodesReducer,
  DonationsStatusTypes:                     DonationsStatusTypesReducer,
  DonationsPeriodicityTypes:                DonationsPeriodicityTypesReducer
}

export function debug(reducer: ActionReducer<string>): ActionReducer<string> {
  return function(state, action) {
    return reducer(state, action);
  };
}

export const metaReducers : MetaReducer[] = [hydrationMetaReducer]