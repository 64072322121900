export class SepaRes {
    
    first=                                        0;
    size=                                         0;
    totalPages=                                   0;
    totalCount=                                   0;
    items:                                        Sepa[] = [];
    stateStatus=                                  "first_assignement";
}

export class Sepa {
    id=                                           "";
    from=                                         "";
    to=                                           "";
    stgUri=                                       "";
    sasUri=                                       "";
    name=                                         "";
    sentToBank=                                   false;
    selected=                                     false;
}