import { createReducer, on } from "@ngrx/store";
import { Donation, DonationsRes } from "src/app/models";
import * as actions from 'src/app/store/actions';

export interface donationInSepaResState {
    DonationInSepaRes : DonationsRes
}

export const initialUserEntries : DonationsRes = new DonationsRes() ;

export const DonationsInSepaReducer = createReducer(
    initialUserEntries,

    on(actions.storeDonationsInAsepaSuccess , ( state , { donationsRes , sepaId } ) => {

        return     donationsRes;

       /*  const myItems = [ ...donationsRes.items ].map(( d : Donation ) => {

            return { ...d , sepaId : sepaId , stateStatus : 'donations_in_sepa_assignements' };
        }) 

        return { ...state , items : [ ...myItems , ...state.items ] , status : 'donations_in_sepa_assignements' }; */
    }) 
) 