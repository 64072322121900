import { createReducer, on } from "@ngrx/store";
import { DonationStatusTypes } from "src/app/models";
import * as actions from 'src/app/store/actions';

export interface DoantionsStatusState {
    DoantionsStatus : DonationStatusTypes[];
}

export const initialDonationStatusTypeEntries : DonationStatusTypes[] = [] ;

export const DonationsStatusTypesReducer = createReducer(
    initialDonationStatusTypeEntries,

    on( actions.storeDonationsStatusTypesSuccess  , (state , { donationsStatusTypes }) => {

        state = donationsStatusTypes;

        return state;
    })
) 