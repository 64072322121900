import { Injectable } from '@angular/core';
import { Validators , FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService {

  ValidateEmail( email:string ):boolean{

    const emailControl = new FormControl(email);

    emailControl.setValidators(Validators.email);

    emailControl.updateValueAndValidity();

    return emailControl.valid;
  }
}
