import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { DonationProjects } from "src/app/models";

export const storeDonationProject = createAction(
    '[Donation Project] store donation project',
    props<{ order : string }>()
)

export const storeDonationProjectSuccess = createAction(
    '[Donation Project] store donation project sucess',
    props<{ donationProjects : DonationProjects[] }>()
)

export const storeDonationProjectFailure = createAction(
    '[Donation Project] store donation project failure',
    props<{ error : HttpErrorResponse }>()
)