import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError,  map, switchMap } from 'rxjs/operators';
import { DonationProjectsService } from 'src/app/donations/services';
import { DonationProjects } from 'src/app/models';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationProjectEffects {

    constructor( 
        private action$ :                   Actions,
        private donationProjectService:     DonationProjectsService
    ){}

    storeDonationProjects$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.storeDonationProject),
            switchMap( () =>
                {
                    return this.donationProjectService.LoadDonationProjects()
                    .pipe(
                        map( ( donationProjects : DonationProjects[] ) => {


                            return actions.storeDonationProjectSuccess ({ donationProjects });
                        }),
                        catchError(error => {
                            return of( actions.storeDonationProjectFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}