import { createAction, props } from "@ngrx/store";
import { DonationAuditLogsfilters } from "src/app/models";

export const AddDonationAuditLogsFilters = createAction(
    '[DonationAuditLogsFilters] add donation auditlog filter',
    props<{ donationAuditLogsfilters : DonationAuditLogsfilters }>()
)

export const modifySizeLogsFilter = createAction(
    '[Donation Filters] modify size logs filter',
    props<{ size : number }>()
)

export const modifyFirstlogsFilter = createAction(
    '[Donation Filters] modify first logs filter',
    props<{ first : number }>()
)