import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { ApiResponse, DonationsForSepaFilters, Sepa, SepaRes, SepasFilters } from "src/app/models"

export const getSepas = createAction(
    '[Sepas] get SEPA by filters',
    props<{ sepaFilters : SepasFilters }>()
)

export const getSepasSuccess = createAction(
    '[Sepas] get SEPA by filters success',
    props<{ sepaResponse : SepaRes }>()
)

export const getSepasFailure = createAction(
    '[Sepas] get SEPA by filters failure',
    props<{ error : HttpErrorResponse }>()
)

export const addSasUriToSepa = createAction(
    '[Sepas] get sasUri for sepa',
    props<{ sepaSasUri : { sepaId : string , uri : string , from : string , to : string }}>()
)

export const addSasUriToSepaSuccess = createAction(
    '[Sepas] get sasUri for sepa success',
    props<{ sepaSasUri : { sepaId : string , sasUri : string } }>()
)

export const addSasUriToSepaFailure = createAction(
    '[Sepas] get sasUri for sepa failure',
    props<{ error : HttpErrorResponse }>()
)

export const storeSepa = createAction(
    '[Sepas] store new Sepa in state',
    props<{ donationsForSepaIds : string[] , donationsForSepaFilters : DonationsForSepaFilters , sepaName : string }>()
)

export const storeSepaSuccess = createAction(
    '[Sepas] store new Sepa in state success',
    props<{ sepa : Sepa }>()
)

export const storeSepaFailure = createAction(
    '[Sepas] store new Sepa in state failure',
    props<{ error : HttpErrorResponse }>()
)

export const setSepaAsSelected = createAction(
    '[Sepas] set sepa as Selected',
    props<{ sepa : Sepa }>()
)

export const setSepaAsUnSelected = createAction(
    '[Sepas] set sepa as unSelected',
    props<{ sepa : Sepa }>()
)

export const setSepaAsSentToBank = createAction(
    '[Sepas] set sepas as sent to bank',
    props<{ sepasIds : string[] }>()
)

export const setSepaAsSentToBankSuccess = createAction(
    '[Sepas] set sepas as sent to bank success',
    props<{ response : ApiResponse , sepasIds : string[] }>()
)

export const setSepaAsSentToBankFailure = createAction(
    '[Sepas] set sepas as sent to bank failure',
    props<{ error : HttpErrorResponse }>()
)

