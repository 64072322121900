import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { DonationStatusTypes } from "src/app/models";

export const storeDonationsStatusTypes = createAction(
    '[Donation status types] store donation status types',
    props<{ order : string }>()
)

export const storeDonationsStatusTypesSuccess = createAction(
    '[Donation status types] store donation status types success',
    props<{ donationsStatusTypes : DonationStatusTypes[] }>()
)

export const storeDonationsStatusTypesFailure = createAction(
    '[Donation status types] store donation status types failure',
    props<{ error : HttpErrorResponse }>()
)