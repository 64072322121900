import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap , map, switchMap } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import { Donation } from 'src/app/models';
import { ComponentToSpinnerService } from 'src/app/shared/services';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationsEffects {

    constructor( 
        private action$ :                   Actions,
        private donationsService:           DonationsService,
        private componentToSpinnerService:  ComponentToSpinnerService
    ){}

    loadDonations$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.getDonationsFiltered),
            switchMap(action =>
                {
                    
                    const { donationsFilters }  = action ||{};
                    
                    return this.donationsService.LoadDonationsFiltered( donationsFilters )
                    .pipe(
                        map(donationsRes => {

                            const { first , size } = donationsFilters || {};
                            
                            donationsRes = { ...donationsRes , items : donationsRes.items.map(( d : Donation ) => { 

                                return d.type === 1 ? { ...d , specific : true , associated : false } : { ...d , specific : false , associated : true ,first : first ? first : 1 , size : size ? size : 10 };
                            
                            })}

                            return actions.getDonationsFilteredSuccess ({donationsRes});
                        }),
                        catchError(error => {
                            return of( actions.getDonationsFilteredFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    loadSingleDonations$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.getDonationSingleDonation),
            switchMap(action =>
                {
                    
                    const { donationId }  = action ||{};
                    
                    return this.donationsService.LoadSingleDonationById( donationId )
                    .pipe(
                        map(donation => {

                            return actions.getDonationSingleDonationSuccess ({ donation });
                        }),
                        catchError(error => {
                            return of( actions.getDonationsFilteredFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    updateDonation$ = createEffect(() => {

        return this.action$
        .pipe(

            ofType(actions.updateDonations),
            mergeMap(( action ) => {

                const { donationToUpdate  } = action || {};

                return this.donationsService.UpdateDonation( donationToUpdate )
                .pipe(

                    map(( ) =>{

                        return actions.updateDonationsSuccess({ donationToUpdate });
                        
                    }),
                    catchError(error => {
                        return of( actions.updateDonationsFailure({ error : error }) )
                    })
                )
            })
        )
    })

    loadDonationsInSepa$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.StoreDonationsInAsepa),
            switchMap(action =>
                {
                    const { donationsInsepaFilters : { sepaId } }  = action ||{};
                    
                    return this.donationsService.LoadDonationsInSepas( action.donationsInsepaFilters )
                    .pipe(
                        map(donationsRes => {

                            return actions.storeDonationsInAsepaSuccess ({donationsRes , sepaId});
                        }),
                        catchError(error => {
                            return of( actions.getDonationsFilteredFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    deleteDonationsInSepa$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.deleteDonation),
            switchMap(action =>
                {
                    const { donationId }  = action ||{};
                    
                    return this.donationsService.DeleteDonation( donationId )
                    .pipe(
                        map(donationRes => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return actions.deleteDonationSuccess ({ donationRes , donationId});
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.deleteDonationFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    StoreXlsDonationsLogs$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.storeXlsDonationLog),
            switchMap(action =>
                {
                    
                    const { donationsIds }  = action ||{};
                    
                    return this.donationsService.SendDonationsIdsDownloaded( donationsIds )
                    .pipe(
                        map( ( apiResponse ) => {

                            return actions.storeXlsDonationLogSuccess ({ apiResponse });
                        }),
                        catchError(error => {
                            return of( actions.storeXlsDonationLogFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })
}