import { createReducer, on } from "@ngrx/store";
import { DonationProjects } from "src/app/models";
import * as actions from "src/app/store/actions";

export interface donationProjectsState {
    DonationProjects : DonationProjects[];
}

export const initialUserEntries : DonationProjects[] = [ new DonationProjects() ] ;

export const DonationProjectsReducer = createReducer(
    initialUserEntries,

    on( actions.storeDonationProjectSuccess , (state , { donationProjects }) => {

        state = donationProjects.map(( dp ) => { return { ...dp , status : 'projects_assignement' } });
        return state;
    }),

    on( actions.storeDonationProjectFailure , (state , { error }) => {

        state[0] = { ...state[0] , error : error };
        return state;
    }),
) 