import { HttpErrorResponse } from '@angular/common/http';
import { createAction , props } from '@ngrx/store';
import { ApiResponse, Donation, DonationToUpdate, DonationsFilters, DonationsRes } from 'src/app/models';
import { DonationsInsepaFilters } from 'src/app/models/donations/donationsFilters.models';

//#region                                 GET
export const getDonationsFiltered = createAction(
    '[Donations] get donations by filters',
    props<{ donationsFilters : DonationsFilters }>()
)

export const getDonationsFilteredSuccess = createAction(
    '[Donations] get donations by filters success',
    props<{ donationsRes : DonationsRes }>()
)

export const getDonationsFilteredFailure = createAction(
    '[Donations] get donations by filters failure',
    props<{ error : HttpErrorResponse }>()
)

export const getDonationSingleDonation = createAction(
    '[Donation single] get single donation',
    props<{ donationId : string }>()
)

export const getDonationSingleDonationSuccess = createAction(
    '[Donation single] get single donation success',
    props<{ donation : Donation }>()
)

export const getDonationSingleDonationFailure = createAction(
    '[Donation single] get single donation failure',
    props<{ error : HttpErrorResponse }>()
)
//#endregion                         GET

//#region                                 UPDATE
export const updateDonations = createAction(
    '[Donations] update donations by filters',
    props<{ donationToUpdate : DonationToUpdate }>()
)

export const updateDonationsSuccess = createAction(
    '[Donations] update donations by filters success',
    props<{ donationToUpdate ? : DonationToUpdate }>()
)

export const updateDonationsFailure = createAction(
    '[Donations] update donations by filters failure',
    props<{ error : HttpErrorResponse }>()
)
//#endregion                              UPDATE

//#region                                 DELETE
export const deleteDonation = createAction(
    '[Donations] delete donations by filters',
    props<{ donationId : string }>()
)

export const deleteDonationSuccess = createAction(
    '[Donations] delete donations by filters success',
    props<{ donationRes : boolean , donationId : string }>()
)

export const deleteDonationFailure = createAction(
    '[Donations] delete donations by filters failure',
    props<{ error : HttpErrorResponse }>()
)
//#endregion                              DELETE

//#region                                 DONATIONS SELECTION
export const selectDonation = createAction(
    '[DonationsToSendEmail] select donation to send by Email',
    props<{ donationSelected : Donation }>()
)

export const unselectDonation = createAction(
    '[DonationsToSendEmail] unselect donation to unselect',
    props<{ donationUnSelected : Donation }>()
)

export const unselectAllDonations = createAction(
    '[DonationsToSendEmail] unselect all donation',
    props<{ order : string }>()
)
//#endregion                              DONATIONS SELECTION

//#region                                 CLEAN STATE
export const cleanDonationToSendByEmailState = createAction(
    '[DonationsToSendEmail] clean donation to send by Email state',
    props<{ order : string }>()
)
//#endregion                              CLEAN STATE

//#region                                 SET AS SENT
export const setAllDonationsAssociatedAsSent = createAction(
    '[Donations Selected] set all associated donations as sent',
    props<{ order : string }>()
)

export const setAllDonationsAssociatedAsSentSuccess = createAction(
    '[Donations Selected] set all associated donations as sent success',
    props<{ order : string }>()
)

export const setAllDonationsAssociatedAsSentFailure = createAction(
    '[Donations Selected] set all associated donations as sent failure',
    props<{ order : string }>()
)
//#endregion                              SET AS SENT

//#region                                 STORE EXCEL LOG

export const storeXlsDonationLog = createAction(
    '[Donation] store xls download store',
    props<{ donationsIds : string[] }>()
)

export const storeXlsDonationLogSuccess = createAction(
    '[Donation] store xls download store',
    props<{ apiResponse : ApiResponse }>()
)

export const storeXlsDonationLogFailure = createAction(
    '[Donation] store xls download store',
    props<{ error : HttpErrorResponse[] }>()
)

//#endregion

//#region                                 SEND DONATION BY EMAIL

export const SetSendByEmailPropertyToDonationFile = createAction(
    '[DonationCertificate] set sentByEmail property in donation file',
    props<{ donationsIds : string[] }>()
)

export const SetSendByEmailPropertyToDonationFileSuccess = createAction(
    '[DonationCertificate] set sentByEmail property in donation file success',
    props<{ dataForReducer : { sendDate : Date , donationsIds : string[] } }>()
)

export const SetSendByEmailPropertyToDonationFileFailure = createAction(
    '[DonationCertificate] set sentByEmail property in donation file failure',
    props<{ error : HttpErrorResponse }>()
)

//#endregion                              SEND DONATION BY EMAIL

//#region                                 DONATIONS IN SEPA

export const StoreDonationsInAsepa = createAction(
    '[Sepas] store donations in a Sepa',
    props<{ donationsInsepaFilters : DonationsInsepaFilters }>()
)

export const storeDonationsInAsepaSuccess = createAction(
    '[Sepas] store donations in a Sepa success',
    props<{ donationsRes : DonationsRes , sepaId : string }>()
)

export const storeDonationsInAsepaFailure = createAction(
    '[Sepas] store donations in a Sepa failure',
    props<{ error : HttpErrorResponse }>()
)

//#endregion                             DONATIONS IN SEPA




