import { HttpErrorResponse } from '@angular/common/http';
import { createAction , props } from '@ngrx/store';
import { Donation, DonationsForSepaFilters, DonationsRes, Sepa } from 'src/app/models';

export const getDonationsSelectedForSepa = createAction(
    '[Donations selected for SEPA] get donations selected for SEPA by filters',
    props<{ donationsForSepaFilters : DonationsForSepaFilters }>()
)

export const getDonationsSelectedForSepaSuccess = createAction(
    '[Donations selected for SEPA] get donations selected for SEPA by filters success',
    props<{ donationsRes : DonationsRes }>()
)

export const getDonationsSelectedForSepaFailure = createAction(
    '[Donations selected for SEPA] get donations selected for SEPA by filters failure',
    props<{ error : HttpErrorResponse }>()
)

export const unSelecteForSepa = createAction(
    '[Donations selected for SEPA] unselect for SEPA by filters',
    props<{ donation : Donation }>()
)

export const SelecteForSepa = createAction(
    '[Donations selected for SEPA] select for SEPA by filters',
    props<{ donation : Donation }>()
)

export const addSepaInfoToDonation = createAction(
    '[Donations selected for SEPA] add sepa info to donation',
    props<{ sepa : Sepa , donationsForSepaIds : string[]; }>()
)

export const selectAllDonationsForSepa = createAction(
    '[Donations selected for SEPA] select all donations for SEPA',
    props<{ order: string }>()
)

export const deSelectAllDonationsForSepa = createAction(
    '[Donations selected for SEPA] deselect all donations for SEPA',
    props<{ order: string }>()
)





