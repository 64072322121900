import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import { ApiResponse } from 'src/app/models';
import * as actions from 'src/app/store/actions';

@Injectable()

export class PaymentEffects {

    constructor( 
        private action$ :                   Actions,
        private donationsService:           DonationsService,
    ){}

    ModifyPayment$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.modifyPayment),
            switchMap(action =>
                {
                    const { payment }  = action ||{};
                    
                    return this.donationsService.ModifyPayment( payment )
                    .pipe(
                        map( ( paymentRes : ApiResponse ) => {

                            if( paymentRes.isSuccess ){

                                return actions.modifyPaymentSuccess ({payment});

                            }else {

                                return actions.modifyPaymentSuccess ({});
                            }
 
                        }),
                        catchError(error => {
                            return of( actions.modifyPaymentFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    DeletePayment$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.deletePayment),
            switchMap(action =>
                {
                    const { payment }  = action ||{};
                    
                    return this.donationsService.DeletePayment( payment.id )
                    .pipe(
                        map( ( paymentRes : ApiResponse ) => {

                            return actions.deletePaymentSuccess ({ paymentRes , payment });
                        }),
                        catchError(error => {
                            return of( actions.deletePaymentFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    StorePayment$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.createPayment),
            switchMap(action =>
                {
                    const { payment }  = action ||{};
                    
                    return this.donationsService.CreatePayment( payment )
                    .pipe(
                        map( ( paymentRes : ApiResponse ) => {

                            return actions.createPaymentSuccess ({ paymentRes , payment });
                        }),
                        catchError(error => {
                            return of( actions.createPaymentFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}