import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DonationFileService {

  constructor(
    private http:                                  HttpClient
  ) { }

  DownloadFile( blobUrl : string , blobName : string ):void{

    this.http.get(blobUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
      
      const filename = `${blobName}`;

      const binaryData :  BlobPart[] = [];

      binaryData.push((blob as BlobPart));

      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData));

      if (filename){

        downloadLink.setAttribute('download', filename);

        document.body.appendChild(downloadLink);
      }
        
      downloadLink.click();

    });
  }
}
