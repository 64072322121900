import { Injectable } from '@angular/core';
import { Donation, DonationProjects, DonationStatusTypes, File } from 'src/app/models';
import { FormatDatesService } from 'src/app/shared/services';
import { DonationPeriodicityTypes } from 'src/app/models/donations/donationsStatusRes.models';
import { Payment } from 'src/app/models/donations/donation.models';

@Injectable({
  providedIn: 'root'
})

export class DonationsHelperService {

  constructor(
    private formatDatesService:                                                           FormatDatesService
  ) { }

  TranslateDonationStatus( donationsStatus : DonationStatusTypes[] ):DonationStatusTypes[]{

    const myDonationsStatusTypes = donationsStatus.map(( ds : DonationStatusTypes ) => {

      const { type } = ds || {};

      switch (type) {
        case 'Pending' :
          ds.type = 'Pendiente'
          break;
        case 'Processed' :
          ds.type = 'Procesada'
          break;
        case 'Paid' :
          ds.type = 'Pagada'
          break;
        case 'Cancelled' :
          ds.type = 'Cancelada'
          break;
      
        default: ds.type = type;
          break;
      }

      return ds;
    })

    return myDonationsStatusTypes;
  }

  TranslateDonationPeriodicities( donationsStatus : DonationStatusTypes[] ):DonationStatusTypes[]{

    const myDonationsPeridocities = donationsStatus.map(( ds : DonationPeriodicityTypes ) => {

      const { type } = ds || {};

      switch (type) {
        case 'Mensual' :
          ds.type = 'Mensual'
          break;
        case 'Anual' :
          ds.type = 'Anual'
          break;
      
        default: ds.type = type;
          break;
      }

      return ds;
    })

    return myDonationsPeridocities;
  }


  GetDonationStatusValue( danationStatusNumber : number ):string{
    
    let myValue = "";

    switch (danationStatusNumber) {
      case 0:
        myValue = "Pendiente"
        break;
      case 1:
        myValue = "Procesada"
        break;
      case 2:
        myValue = "Pagada"
        break;
      case 3:
        myValue = "Cancelada"
        break;
      default:myValue = "Pendiente"
        break;
    }
    
    return myValue;
  }

  GetDonationTypeValue( danationTypeNumber : number ):string{
    
    let myValue = "";

    switch (danationTypeNumber) {
      case 0:
        myValue = "Especifica"
        break;
      case 1:
        myValue = "Asociada"
        break
      default:myValue = "Asociada"
        break;
    }
    
    return myValue;
  }

  FormatDonationResponse( donations : Donation[] , donationsSelected : Donation[] , donationProjects : DonationProjects[] ):Donation[]{
    
    return donations.map((d : Donation) => {

      const myDonationProject = donationProjects.find(( dp : DonationProjects ) => dp.id === d.projectId) ;

      return {
        ...d , 
        statusValue : this.GetDonationStatusValue(d.status) , 
        donationDate : new Date(d.dateTime).toLocaleDateString() ,
        sentByEmail : d.files.find(( f : File ) => f.sentByEmail) ? true : false,
        project : { id : d.projectId , project : myDonationProject ? myDonationProject.name : "" },
        hasCertificate : d.files.some(item => item.type === 1) ? true : false,
        selected : donationsSelected.find(( ds ) => ds.id === d.id && !d.associatedCertificateSent ) ? true : false
      };
    });
  }

  FormaDonationForSepaResponse( donations : Donation[] ): Donation[]{

    return donations.map((d : Donation) => {

      return { ...d , donationDate : this.formatDatesService.FormatDateForTable(d.dateTime) }
    })
  }

  GetSelectedDonationsWithoutCertificate( donationsSelected : Donation[] ):Donation[]{

    const donationsSelectedWithoutCertificate : Donation[] = [];

    donationsSelected.forEach(item => {

      const allFilesNotTypeOne = item.files.every(file => file.type !== 1);

      if ( allFilesNotTypeOne && item.specific ) {

        donationsSelectedWithoutCertificate.push(item);
      }
    });

    return donationsSelectedWithoutCertificate;
  }

  GetDonationsAssociatedWithoutCertificateSent( donations : Donation[] ):Donation[]{

    return donations.filter(( d : Donation ) => d.associated && !d.associatedCertificateSent);
  }

  GetSelectedDonationsToSendByEmail( donationsSelected : Donation[] ):Donation[]{

    return donationsSelected.filter(( d : Donation ) => d.hasCertificate && d.specific);
  }

  GetTotalFromPayments( payments : Payment[] ):number{

    return payments.filter((p : Payment) => !p.deleted ).reduce((total, currentPayment) => total + currentPayment.amount, 0);
  }
}
