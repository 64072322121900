import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const storeBankCodes = createAction(
    '[Donation Bank Codes] store bank codes',
    props<{ order : string }>()
)

export const storeBankCodesSuccess = createAction(
    '[Donation Bank Codes] store bank codes success',
    props<{ bankCodes : string[] }>()
)

export const storeBankCodesFailure = createAction(
    '[Donation Bank Codes] store bank codes failure',
    props<{ error : HttpErrorResponse }>()
)