import { createReducer, on } from "@ngrx/store";
import { Donation, DonationsRes } from "src/app/models";
import * as actions from 'src/app/store/actions';

export interface donationResState {
    DonationRes : DonationsRes
}

export const initialUserEntries : DonationsRes = new DonationsRes() ;

export const DonationsForSepaReducer = createReducer(

    initialUserEntries,

    on( actions.getDonationsSelectedForSepaSuccess , (state , { donationsRes }) => {

        const myItems = [ ...donationsRes.items ].map((d : Donation) => {

            return { ...d , selected : true };
        });

        state = {...donationsRes , status:'donations_assignements' , items : myItems};

        return state;
    }),
    
    on( actions.SelecteForSepa , (state , { donation }) => {

        const myDonations = state.items.map(( d : Donation ) => {

            return d.id === donation.id ? { ...d , selected : !d.selected } : d;
        })

        return { ...state , items : myDonations };
    }),

    on( actions.addSepaInfoToDonation , ( state , { sepa , donationsForSepaIds } ) => {

        const mySepas = [ ...state.items ].map(( d : Donation ) => {

            if( donationsForSepaIds.find(( donationId : string ) => donationId === d.id ) ){

                return { ...d , sepasDonations : [ ...d.sepasDonations , { sepaId : sepa.id , donationId : d.id } ] };
            
            } else {

                return {...d};
            }
        })

        return { ...state , items : mySepas };
    }), 

    on( actions.selectAllDonationsForSepa , ( state , { order } ) => {

        return { ...state , items : [ ...state.items ].map(( d : Donation ) => { return { ...d , selected : true } }) };
    }),

    on( actions.deSelectAllDonationsForSepa , ( state , { order } ) => {

        return { ...state , items : [ ...state.items ].map(( d : Donation ) => { return { ...d , selected : false } }) };
    })
) 