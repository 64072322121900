import { HttpErrorResponse } from '@angular/common/http';
import { createAction , props } from '@ngrx/store';
import { ProfessionalsJSONRes, UserInfo } from 'src/app/models';

export const addUser = createAction('Add User' , props<UserInfo>());
export const removeUser = createAction('Remove User' , props<UserInfo>())
export const clearUser = createAction('Clear User');
export const returnUsers = createAction('Return User');

export const addProfessionalAvatar = createAction(
    '[User] add professional avatar to user',
    props<{ avatar : string }>()
)

export const getProfessionalsJsonAction = createAction(
    '[Professionals] Get professionals json' , 
    props<{ professionalsFilters : object }>()
);

export const getProfessionalsJsonActionSuccess = createAction(
    '[Professionals] Get professionals json success',
    props<{ professionalsJSONres : ProfessionalsJSONRes }>()
)

export const getProfessionalsJsonActionFailure = createAction(
    '[Professionals] Get professionals json failure',
    props<{ error: HttpErrorResponse }>()
);
