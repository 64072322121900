import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { DonationAuditLogResponse, DonationAuditLogsfilters } from "src/app/models";

export const AddSingleDonationAuditLogs = createAction(
    '[DonationAuditLogs] add single donation audit logs',
    props<{ donationsAuditsLogsFilters : DonationAuditLogsfilters }>() 
)

export const AddSingleDonationAuditLogsSuccess = createAction(
    '[DonationAuditLogs] add single donation audit logs success',
    props<{ donationAuditLogResponse : DonationAuditLogResponse }>() 
)

export const AddSingleDonationAuditLogsFailure = createAction(
    '[DonationAuditLogs] add single donation audit logs failure',
    props<{ error : HttpErrorResponse }>() 
)

export const AddAllDonationAuditLogs = createAction(
    '[DonationAuditLogs] add all donation audit logs',
    props<{ donationsAuditsLogsFilters : DonationAuditLogsfilters }>() 
)

export const AddAllDonationAuditLogsSuccess = createAction(
    '[DonationAuditLogs] add all donation audit logs success',
    props<{ donationAuditLogResponse : DonationAuditLogResponse }>() 
)

export const AddAllDonationAuditLogsFailure = createAction(
    '[DonationAuditLogs] add all donation audit logs failure',
    props<{ error : HttpErrorResponse }>() 
)

