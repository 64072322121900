import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, BlobReference, DonationsForSepaFilters, Sepa, SepaRes, SepasFilters } from 'src/app/models';
import { FormatDatesService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SepaDonationsService {

  constructor(
    private http:                                        HttpClient,
    private formatDatesService:                          FormatDatesService
  ) { }

  GenerateSepa( donationsIds : string[] , filters : DonationsForSepaFilters , sepaName : string ):Observable<Sepa>{

    return this.http.post<Sepa>(
      `${environment.donationsService.url}sepa-files/create?From=${this.formatDatesService.AddDaysToADate(filters.from,1)}&To=${this.formatDatesService.AddDaysToADate(filters.to,1)}&First=${filters.first}&Size=${filters.size}`,
      { donationsId : donationsIds , name : sepaName }
    );
  }

  LoadSepa( sepaFilters : SepasFilters ):Observable<SepaRes>{

    const { from , to , size , first } = sepaFilters || {};

    return this.http.get<SepaRes>(`${environment.donationsService.url}sepa-files?From=${from}&To=${to}&First=${first}&Size${size}`);
  }

  GetSepaSasUri( stgUri : string ):Observable<BlobReference>{

    return this.http.get<BlobReference>(`${environment.donationsService.url}sepa-files/get-sasUri?uri=${stgUri}` );
  }

  SetSepasAsSent( sepasIds : string[] ):Observable<ApiResponse>{

    return this.http.put<ApiResponse>(`${environment.donationsService.url}sepa-files/set-as-sent` , sepasIds );
  }

  GetSepaSasUriFromSepaId( sepaId : string ):Observable<BlobReference>{

    return this.http.get<BlobReference>(`${environment.donationsService.url}sepa-files/get-sasUri-from-sepaId?sepaId=${sepaId}`);
  }
}
