import { createReducer, on } from "@ngrx/store";
import * as actions from 'src/app/store/actions';

export interface BankCodesState {
    BankCodesRes : string[];
}

export const initialUserEntries : string[] = [] ;

export const BankCodesReducer = createReducer(
    initialUserEntries,

    on( actions.storeBankCodesSuccess  , (state , { bankCodes }) => {

        state = bankCodes;

        return state;
    })
) 