import { createReducer , on } from '@ngrx/store';
import { ProfessionalsJSONRes } from 'src/app/models';
import * as actions from 'src/app/store/actions';

export interface ProfessionalsJsonState {
    professionalsJson : ProfessionalsJSONRes;
}

const initialState : ProfessionalsJSONRes = new ProfessionalsJSONRes();  

export const ProfessionalsJsonReducer = createReducer(
    initialState,
    on( actions.getProfessionalsJsonActionSuccess , (state , {professionalsJSONres}   ) => {
        return professionalsJSONres;
    }) 
)