import { Component, OnInit } from '@angular/core';
import { 
  faUser, fas , faScaleBalanced ,faUsers ,
  faList , faBookReader ,faCalendarDays , 
  faPhoneFlip , faGraduationCap , faChartColumn,
  faFolderOpen , faGear , faCircleQuestion , 
  faUserDoctor , faBuilding , faPrescriptionBottleMedical , 
  faAnglesRight, IconDefinition ,  faHandHoldingDollar ,faClipboard , faFile
} from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { CheckUserRoleService } from 'src/app/shared/services/helpers/check-user-role.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  activedPage=                                 "donations";

  faUser:IconDefinition = faUser;
  faFile:IconDefinition = faFile;
  faClipboard:IconDefinition = faClipboard;
  faUsers:IconDefinition = faUsers;
  faList:IconDefinition = faList;
  faScaleBalanced:IconDefinition = faScaleBalanced; 
  faUserDoctor:IconDefinition = faUserDoctor; 
  faPrescriptionBottleMedical:IconDefinition = faPrescriptionBottleMedical; 
  faBuilding:IconDefinition = faBuilding; 
  faHandHoldingDollar:IconDefinition = faHandHoldingDollar; 
  faBookReader:IconDefinition = faBookReader;
  faCalendarDays:IconDefinition = faCalendarDays;
  faPhoneFlip:IconDefinition = faPhoneFlip;
  faGraduationCap:IconDefinition = faGraduationCap;
  faChartColumn:IconDefinition = faChartColumn;
  faFolderOpen:IconDefinition = faFolderOpen;
  faGear:IconDefinition = faGear;
  faCircleQuestion:IconDefinition = faCircleQuestion;
  faAnglesRight:IconDefinition = faAnglesRight;

  isAdmin=                          false;
  isReader=                         false;
  isAuditor=                        false;

  constructor(
    private router : Router,
    private  route: ActivatedRoute,
    private checkUserRoleService:                                                           CheckUserRoleService
  ){}

  url$: Observable<string> = new Observable<string>();

  ngOnInit(): void {
    
    this.CheckUserRole();
    this.CheckPath();
  }

  CheckUserRole():void{
    const roles = this.checkUserRoleService.CheckUserRole();

    console.log

    const { isReader , isAdmin , isAuditor } = roles || {};
    if( isReader )this.isReader = isReader;
    if( isAdmin )this.isAdmin = isAdmin;
    if( isAuditor )this.isAuditor = isAuditor;
  }

  CheckPath():void{

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd),map((event: NavigationEnd) => event.url)).subscribe(url => {
      
      this.activedPage = url.split('/')[1];
    })
  }

  GoToPage( goTo : string ):void{
    
    const { location : { hostname } } = window || {};
  
    if( goTo === 'logs' ){
     
      this.router.navigate(['auditLogs']);
    
    }

    if( goTo === 'sepas' ){

      this.router.navigate(['sepas']);

    }

    if( goTo === 'donations' ){

      this.router.navigate(['donations']);

    }
  }
}
