import { DonationAuditLogsEffects } from "./effects/donations/donation-auditlogs.effects";
import { DonationProjectEffects } from "./effects/donations/donation-projects.effects";
import { DonationsForSepaEffects } from "./effects/sepas/donations-sepa-selected.effets";
import { DonationsEffects } from "./effects/donations/donations.effects";
import { ProfessionalsJsonEffects } from "./effects/users/user.effects";
import { SepasEffects } from "./effects/sepas/sepas.effects";
import { BankCodesEffects } from "./effects/donations/bank-codes.effets";
import { DonationsCertificatesEffects } from "./effects/donations/donations.certificates.effects";
import { DonationsStatusEffects } from "./effects/donations/donations-status-types.effects";
import { DonationsPeriodicityTypesEffects } from "./effects/donations/donations-periodicity-types.effets";
import { PaymentEffects } from "./effects/donations/payments.effects";

export const metaEffects = [
    ProfessionalsJsonEffects,
    DonationsEffects,
    DonationProjectEffects,
    DonationAuditLogsEffects,
    DonationsCertificatesEffects,
    DonationsForSepaEffects,
    SepasEffects,
    BankCodesEffects,
    DonationsStatusEffects,
    DonationsPeriodicityTypesEffects,
    PaymentEffects
]