import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import { DonationPeriodicityTypes } from 'src/app/models/donations/donationsStatusRes.models';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationsPeriodicityTypesEffects {

    constructor( 
        private action$ :                                        Actions,
        private donationsService:                                DonationsService,
    ){}

    loadDonationPeriodicityTypes$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.storeDonationsPeriodicityTypes),
            switchMap(( ) =>
                {
                    return this.donationsService.LoadDonationsPeriodicityTypes( )
                    .pipe(
                        map(( res : object ) => {

                            const donationsPeriodicityTypes : DonationPeriodicityTypes[] = [];

                            const statusKeys = Object.keys( res );

                            const statusValues = Object.values( res );

                            for (let i = 0; i < statusKeys.length; i++) {
                                
                                donationsPeriodicityTypes.push({ type : statusKeys[i] , num : statusValues[i] })
                            }

                            return actions.storeDonationsPeriodicityTypesSuccess ({ donationsPeriodicityTypes });
                        }),
                        catchError(error => {

                            return of( actions.storeDonationsPeriodicityTypesFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}