export class ProfessionalsJSONRes {
    payload: ProfessionalFromJson[] = [] ;
    success  = false;
}

export class ProfessionalFromJson {
    id             = 0;
    username       = '';
    nombre         = '';
    apellidos      = '';
    apellidos_dos: null | string = '';
    dni:           null | string = '';
    email          = '';
    units:         Unit[] = [];
    clinics:       GoingUpClinic[] = [];
    code:          string[] = [];
    bip_id:        null | string = '';
    avatar:        null | string = '';
}

export class GoingUpClinic {
    name:    string;
    key_cli: null | string;
}

export class Unit {
    id:   number;
    name: string;
}



