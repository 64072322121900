import { HttpErrorResponse } from "@angular/common/http"
import { createAction, props } from "@ngrx/store"
import { CertificateResponse, ApiResponse } from "src/app/models"

export const AddCertificateToDonation = createAction(
    '[DonationCertificate] add certificate to donation',
    props<{ donationsIds : string[] }>()
)

export const AddCertificateToDonationSuccess = createAction(
    '[DonationCertificate] add certificate to donation success',
    props<{ certificates ? : CertificateResponse[] }>()
)

export const AddCertificateToDonationFailure = createAction(
    '[DonationCertificate] add certificate to donation failure',
    props<{ error : HttpErrorResponse }>()
)

export const SetCertificateAsSentInAssociatedDonation = createAction(
    '[DonationCertificate] set certificate as sent in donation',
    props<{ donationsIds : string[] }>()
)

export const SetCertificateAsSentInAssociatedDonationSuccess = createAction(
    '[DonationCertificate] set certificate as sent in donation success',
    props<{ setCertificateAsSentResponse : ApiResponse , donationsIds : string[] }>()
)

export const SetCertificateAsSentInAssociatedDonationFailure = createAction(
    '[DonationCertificate] set certificate as sent in donation failure',
    props<{ error : HttpErrorResponse }>()
)

export const SetAllCertificateAsSentInAssociatedDonation = createAction(
    '[DonationCertificate] set all certificate as sent in donation',
    props<{ year : number }>()
)

export const SetAllCertificateAsSentInAssociatedDonationSuccess = createAction(
    '[DonationCertificate] set all certificate as sent in donation success',
    props<{ setCertificateAsSentResponse : ApiResponse }>()
)

export const SetAllCertificateAsSentInAssociatedDonationFailure = createAction(
    '[DonationCertificate] set all certificate as sent in donation failure',
    props<{ error : HttpErrorResponse }>()
)