import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DonationProjects } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DonationProjectsService {

  constructor(
    private http :                                                                  HttpClient
  ) { }

  LoadDonationProjects() :Observable<DonationProjects[]> {
    return this.http.get<DonationProjects[]>(`${environment.donationsService.url}Projects`);
  }
}
