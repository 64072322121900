import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError , map, switchMap } from 'rxjs/operators';
import { DonationFileService } from 'src/app/donations/services';
import { SepaDonationsService } from 'src/app/sepas/services/httpRequests/sepa-donations.service';
import { ComponentToAlertComponentService, ComponentToSpinnerService, FormatDatesService } from 'src/app/shared/services';
import * as actions from 'src/app/store/actions';
import { addSepaInfoToDonation } from 'src/app/store/actions';
import { AlertContent, ApiResponse, BlobReference } from 'src/app/models';

@Injectable()

export class SepasEffects {

    constructor( 
        private action$ :                                       Actions,
        private sepaDonationsService:                           SepaDonationsService,
        private donationFileService:                            DonationFileService,
        private formatDateService:                              FormatDatesService,
        private componentToSpinnerService:                      ComponentToSpinnerService,
        private componentToAlertComponentService:               ComponentToAlertComponentService
    ){}

    storeSepas$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( actions.storeSepa ),
            switchMap(action =>
                {
                    const { donationsForSepaFilters , donationsForSepaIds , sepaName }  = action ||{};
                    
                    return this.sepaDonationsService.GenerateSepa( donationsForSepaIds , donationsForSepaFilters , sepaName ).pipe(

                        switchMap(sepa => {

                            this.componentToSpinnerService.sendMessage('hide');

                            this.donationFileService.DownloadFile(sepa.sasUri , sepaName);

                            return [
                                actions.storeSepaSuccess({ sepa }),
                                addSepaInfoToDonation({ sepa , donationsForSepaIds })
                            ]

                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of(actions.storeSepaFailure({ error: error }));
                        })
                    )
                }
            )
        )
    })

    loadSepas$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( actions.getSepas ),
            switchMap(action =>
                {
                    const { sepaFilters }  = action ||{};
                    
                    return this.sepaDonationsService.LoadSepa( sepaFilters )
                    .pipe(
                        map(sepaResponse => {

                            return actions.getSepasSuccess ({sepaResponse});
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.getSepasFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    loadSepaSasUri$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( actions.addSasUriToSepa ),
            switchMap(action =>
                {
                    const { sepaSasUri : { sepaId , uri , from , to } }  = action ||{};
                    
                    return this.sepaDonationsService.GetSepaSasUri( uri )
                    .pipe(
                        map(( blobReference : BlobReference ) => {

                            const { sasUri } = blobReference || {};
                            
                            const sepaSasUri : { sepaId : string , sasUri : string } = { sepaId : sepaId , sasUri : sasUri };

                            this.donationFileService.DownloadFile( sasUri , `${this.formatDateService.AddDaysToADate(from , 1)}-${this.formatDateService.AddDaysToADate(to , 1)}` );

                            return actions.addSasUriToSepaSuccess ({ sepaSasUri });
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.addSasUriToSepaFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    setSepaAsSentToBank$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( actions.setSepaAsSentToBank ),
            switchMap(action =>
                {
                    const { sepasIds }  = action ||{};
                    
                    return this.sepaDonationsService.SetSepasAsSent( sepasIds )
                    .pipe(
                        map(( response : ApiResponse ) => {

                            this.componentToSpinnerService.sendMessage('hide');

                            const alert : AlertContent = {
                                title: 'Atención',
                                content: 'Los sepas seleccionados fueron marcados como enviados y los cobros añadidos',
                                color:'rgb(232 , 252 , 239)',
                                borderColor : '1px solid rgb(34 , 195 , 89)',
                                icon: 'faCircleCheck',
                                iconColor:'rgb(161 , 18 , 18)',
                                navigateTo : 'navigateTo'
                            }
                        
                            this.componentToAlertComponentService.sendMessage(alert);

                            return actions.setSepaAsSentToBankSuccess ({ response , sepasIds });
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            const alert : AlertContent = {
                                title: 'Atención !!',
                                content: 'Fallo al marcar los sepas como enviados',
                                color:'rgb(252 , 244 , 232)',
                                borderColor : '1px solid rgb(240 , 183 , 117)',
                                icon: 'faTriangleExclamation',
                                iconColor:'rgb(161 , 18 , 18)'
                            }
                        
                            this.componentToAlertComponentService.sendMessage(alert);

                            return of( actions.setSepaAsSentToBankFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}