import { HttpErrorResponse } from "@angular/common/http";

export class DonationsRes {
    status                                                              = 'first_assignement';
    error?:                                                             HttpErrorResponse;
    first                                                               = 0;
    size                                                                = 0;
    totalPages                                                          = 0;
    totalCount                                                          = 0;
    items:                                                              Donation[] = [];
}

export class Donation {
    id                                                                  = '';
    firstName:                                                          string | undefined | null = '';
    lastName:                                                           string | undefined | null = '';
    amount:                                                             string | number | undefined | null = 0;
    accountNumber:                                                      string | undefined | null = '';
    dateTime:                                                           string = new Date().toString();
    donationDateFormatted?:                                             string = new Date().toString();
    status                                                              = 2;
    statusValue?=                                                       "" ;
    documentNumber:                                                     string | undefined | null = '';
    email:                                                              string | undefined | null = '';
    address:                                                            string | undefined | null = '';
    specific                                                            = false;
    associated                                                          = false;
    starred                                                             = false;
    filesStgUrl:                                                        { uri : string , description : string }[] = [{ uri : '' , description : '' }];
    project:                                                            {id:string , project:string};
    projectId=                                                          "";
    selected                                                            = false;
    type                                                                = 0;
    city =                                                              "";
    country =                                                           "";
    files :                                                             File[] = [ new File() ];
    hasCertificate =                                                    false;
    postalCode =                                                        "";
    prefix =                                                            "";
    province =                                                          "";
    phone =                                                             "";
    first =                                                             1;
    size =                                                              10;
    sentByEmail=                                                        false;
    sepaId?=                                                            "";
    stateStatus?=                                                       "";
    sepasDonations:                                                     SepaDonation[] = [];
    associatedCertificateSent=                                          false;
    hasAuditLogs=                                                       false;
    periodicityType=                                                    0;
    payments:                                                           Payment[] = [];
}

export class Payment {
    donationId=                                                         "";
    id=                                                                 "";
    amount=                                                             0;
    createdOn?=                                                         "";
    createdByUser=                                                      "";
    date?=                                                              "";
    time?=                                                              "";
    deleted=                                                            false;
}

export class SepaDonation{
    sepaId=                                                             "";
    donationId=                                                         "";
}

export class File {
    uri =                                                               "";
    description? =                                                       "";
    sentByEmail? =                                                       false;
    dateTime? =                                                          "";
    type? =                                                              0;
    sentByEmailDateTime? :                                               Date = new Date();
}

export class DonationToUpdate {
    id =                                                                 "";
    firstName:                                                           string | null | undefined = "";
    lastName :                                                           string | null | undefined = "";
    amount :                                                             number | string | null | undefined = 0;
    accountNumber :                                                      string | null | undefined = "" ;
    documentNumber :                                                     string | null | undefined = "";
    email :                                                              string | null | undefined = "";
    prefix =                                                             "";
    phone =                                                              "";
    address :                                                            string | null | undefined = "";
    city =                                                               "";
    province =                                                           "";
    postalCode =                                                         "";
    country =                                                            "";
    type =                                                               0;
    status =                                                             0;
    starred? =                                                           false;
    projectId?=                                                          "";
    periodicityType?                                                     =0;
    dateTime=                                                            "";
}

