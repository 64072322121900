import { createReducer, on } from "@ngrx/store";
import * as actions from "src/app/store/actions";
import { Donation } from "src/app/models";

export interface donationsToSendByEmailState {
    donationsToSendByEmail : Donation[]
}

export const initialUserEntries : Donation[] = [] ;

export const DonationsSelectedReducer = createReducer(
    initialUserEntries,

    on( actions.selectDonation , (state , { donationSelected }) => {

        return [donationSelected , ...state]; 
    }),

    on( actions.unselectDonation , (state , { donationUnSelected }) => {
       
        return state.filter( d => d.id !== donationUnSelected.id );
    }),

    on( actions.cleanDonationToSendByEmailState , (state , { order }) => {

        return [];
    }),

    on( actions.unselectAllDonations , ( state , { order } ) => {
        
        if( order === 'clean' ){

            return [];

        }else {
            return state;
        }
    })
) 