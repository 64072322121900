import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import * as actions from 'src/app/store/actions';

@Injectable()

export class BankCodesEffects {

    constructor( 
        private action$ :                                        Actions,
        private donationsService:                                DonationsService
    ){}

    loadBankCodes$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.storeBankCodes),
            switchMap( () =>
                {
                    
                    return this.donationsService.GetBankCodes( )
                    .pipe(
                        map(( bankCodes : string[] ) => {

                            return actions.storeBankCodesSuccess ({ bankCodes });
                        }),
                        catchError(error => {

                            return of( actions.storeBankCodesFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}