import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "src/app/models";
import { Payment } from "src/app/models/donations/donation.models";

export const modifyPayment = createAction(
    '[ Modify Payment ] modify payment',
    props<{ payment : Payment }>()
)

export const modifyPaymentSuccess = createAction(
    '[ Modify Payment ] modify payment success',
    props<{ payment? : Payment }>()
)

export const modifyPaymentFailure = createAction(
    '[ Modify Payment ] modify payment failure',
    props<{ error : HttpErrorResponse }>()
)

export const deletePayment = createAction(
    '[ Delete Payment ] delete payment',
    props<{ payment : Payment }>()
)

export const deletePaymentSuccess = createAction(
    '[ Delete Payment ] delete payment success',
    props<{ paymentRes : ApiResponse , payment : Payment }>()
)

export const deletePaymentFailure = createAction(
    '[ Delete Payment ] delete payment failure',
    props<{ error : HttpErrorResponse }>()
)

export const createPayment = createAction(
    '[ Create Payment ] create payment',
    props<{ payment : { donationId : string , amount: number } }>()
)

export const createPaymentSuccess = createAction(
    '[ Create Payment ] create payment success',
    props<{ paymentRes : ApiResponse , payment : { donationId : string , amount: number } }>()
)

export const createPaymentFailure = createAction(
    '[ Create Payment ] create payment failure',
    props<{ error : HttpErrorResponse }>()
)