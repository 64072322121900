import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError , switchMap, map } from 'rxjs/operators';
import { ProfessionalService } from 'src/app/shared/services/httpResquests/professional.service';
import * as actions from 'src/app/store/actions';

@Injectable()

export class ProfessionalsJsonEffects {

    constructor( 
        private action$ :                   Actions,
        private professionalService:        ProfessionalService
    ){}

    loadDocuments$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.getProfessionalsJsonAction),
            switchMap( () =>
                {
                    return this.professionalService.GetProfessionalsJson()
                    .pipe(
                        map(professionalsJSONres => 
                        { 
                           
                            return actions.getProfessionalsJsonActionSuccess({professionalsJSONres}) 
                        }),
                        
                        catchError(error => {
                            return of( actions.getProfessionalsJsonActionFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })
}
