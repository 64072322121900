import { SepasFilters } from 'src/app/models';
import { createReducer , on } from '@ngrx/store';
import * as actions from 'src/app/store/actions';


export interface SepasFiltersState {
    SepasFilters : SepasFilters
}

export const initialUserEntries : SepasFilters = new SepasFilters() ;

export const SepasFiltersReducer = createReducer(
    
    initialUserEntries,

    on( actions.modifySepasFilters , (state , {sepaFilters})=>{

        state = sepaFilters;

        return state;
    })
) 