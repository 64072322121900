import { createReducer, on } from "@ngrx/store";
import { DonationPeriodicityTypes } from "src/app/models/donations/donationsStatusRes.models";
import * as actions from 'src/app/store/actions';

export interface DoantionsPeriodicityState {
    DoantionsPeriodicity : DonationPeriodicityTypes[];
}

export const initialDonationPeriodicityTypeEntries : DonationPeriodicityTypes[] = [] ;

export const DonationsPeriodicityTypesReducer = createReducer(
    initialDonationPeriodicityTypeEntries,

    on( actions.storeDonationsPeriodicityTypesSuccess  , (state , { donationsPeriodicityTypes }) => {

        state = donationsPeriodicityTypes;

        return state;
    })
) 