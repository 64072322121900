import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableToPopupService {

  private subject = new Subject<string>();
  private downloadsubject = new Subject<string>();

  sendMessage( donationId : string ) {
    this.subject.next(donationId);
  }

  clearMessages() {
    this.subject.next('');
  }

  onMessage(): Observable<string> {
    return this.subject.asObservable();
  }

  sendDownloadPopUpMessage( donationId : string ) {
    this.downloadsubject.next(donationId);
  }

  clearDownloadPopUpMessages() {
    this.downloadsubject.next('');
  }

  onDownloadPopUpMessage(): Observable<string> {
    return this.downloadsubject.asObservable();
  }
}
