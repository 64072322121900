import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError,  map, switchMap } from 'rxjs/operators';
import { AuditlogsService } from 'src/app/audit-logs/services';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationAuditLogsEffects {

    constructor( 
        private action$ :                   Actions,
        private auditlogsService :          AuditlogsService
    ){}

    storeSingleDonationsAuditLogs$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.AddSingleDonationAuditLogs),
            switchMap(action =>
                {
                    const { donationsAuditsLogsFilters } = action || {};
                    
                    return this.auditlogsService.LoadSingleDonationAuditLogs( donationsAuditsLogsFilters )
                    .pipe(
                        map( ( donationAuditLogResponse ) => {

                            return actions.AddSingleDonationAuditLogsSuccess ({ donationAuditLogResponse });
                        }),
                        catchError(error => {
                            return of( actions.AddSingleDonationAuditLogsFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    storeAllDonationsAuditLogs$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.AddAllDonationAuditLogs),
            switchMap(action =>
                {
                    const { donationsAuditsLogsFilters } = action || {};
                    
                    return this.auditlogsService.LoadAllDonatiosnAuditLogs( donationsAuditsLogsFilters )
                    .pipe(
                        map( ( donationAuditLogResponse ) => {

                            return actions.AddAllDonationAuditLogsSuccess ({ donationAuditLogResponse });
                        }),
                        catchError(error => {
                            return of( actions.AddAllDonationAuditLogsFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })
}