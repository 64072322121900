export class BlobReference {
    container=                             "";
    name=                                  "";
    extension=                             "";
    uri=                                   "";
    sasUri=                                "";
    sasExpires=                            "";
    metadata:                              Metadata = new Metadata();
}

export class Metadata {
    additionalProp1=                       "";
    additionalProp2=                       "";
    additionalProp3=                       "";
}