<div  class="app_donations-banner " >
  <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-4 app_donations-logo_container" >

    <img *ngIf="logged" class="app_donations-logo" src="../assets/logos/AF_logotipo fundacion baviera responsable_2023.jpg" alt="logo clinica baviera">
      
  </div>
    
  <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-4 app_donations-auth-container " >
    
    <app-auth (isLogged)="isLogged($event)"  (iframe)="RecibeFrame($event)" ></app-auth>

    </div>
  </div>
  
  <div *ngIf="logged" class="app-main-first-container" >

    <div class="app_main-side_menu_container" >

      <app-side-menu></app-side-menu>

    </div>

    <div  class="app-main-container">
    
  
      <div class="app_donations-top_container bg-white" >
        
        <div class="donations-player_tittle_container" >
          
          <fa-icon class="app_donations-eye_icon" [icon]="faHandHoldingDollar"></fa-icon>

          <span class="app_donations-player_span" >Donaciones Fundación B.R</span>
       
        </div>

      </div>
    
      <div class="app-router-container">
        
        <div>

          <router-outlet *ngIf="!isIframe"></router-outlet>

        </div>

      </div>
    </div>
  </div>
  
  
  
  
  
  
  
  