export class DonationsFilters {
    filterStatus?:                                           string = 'first_assignement';
    associated  ?:                                           boolean = true;
    specific  ?:                                             boolean = false;
    search ?:                                                string = "";
    firstName ?:                                             string = "";
    lastName ?:                                              string = "";
    documentNumber ?:                                        string = "";
    identityCard ?:                                          string = "";
    email ?:                                                 string = "";
    amount ?:                                                number = 0;
    amountOrderDesc?:                                        boolean = false;     
    amountOrderAsc?:                                         boolean = false;     
    fiscalAddress ?:                                         string = "";
    accountNumber ?:                                         string = "";
    donationDate ?:                                          Date = new Date();
    status ?:                                                string = "";
    from?:                                                   string = "";
    to?:                                                     string = "";
    first?:                                                  number = 1;
    size?:                                                   number = 10;
    periodicityType? =                                       0;
    documentIdType? =                                        0;
}

export class DonationsInsepaFilters {

    sepaId=                                                  "";
    first=                                                   1;
    size=                                                    10;
}