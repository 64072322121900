import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap , map } from 'rxjs/operators';
import { DonationsService } from 'src/app/donations/services';
import { AlertContent } from 'src/app/models';
import { ComponentToAlertComponentService, ComponentToSpinnerService } from 'src/app/shared/services';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationsCertificatesEffects {

    constructor( 
        private action$ :                                              Actions,
        private donationsService:                                      DonationsService,
        private componentToAlertComponentService:                      ComponentToAlertComponentService,
        private componentToSpinnerService:                             ComponentToSpinnerService
    ){}

    addCertificateToDonation$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.AddCertificateToDonation),
            mergeMap(( action ) => {
                return this.donationsService.GenerateDonationCertificate( action.donationsIds )
                .pipe(
                    map(( certificates ) =>{
                        
                        if( certificates ){

                            const alert : AlertContent = {
                                title: 'Atención',
                                content: 'Certificados generados para las donaciones seleccionadas',
                                color:'rgb(232 , 252 , 239)',
                                borderColor : '1px solid rgb(34 , 195 , 89)',
                                icon: 'faCircleCheck',
                                iconColor:'rgb(161 , 18 , 18)',
                                navigateTo : 'navigateTo'
                            }
                        
                            this.componentToAlertComponentService.sendMessage(alert);

                            this.componentToSpinnerService.sendMessage('hide');

                            return actions.AddCertificateToDonationSuccess({ certificates });
                        
                        }else {

                            const alert : AlertContent = {
                                title: 'Atención !!',
                                content: 'Fallo al generar certificados para las donaciones seleccionadas',
                                color:'rgb(252 , 244 , 232)',
                                borderColor : '1px solid rgb(240 , 183 , 117)',
                                icon: 'faTriangleExclamation',
                                iconColor:'rgb(161 , 18 , 18)'
                            }
                        
                            this.componentToAlertComponentService.sendMessage(alert);

                            this.componentToSpinnerService.sendMessage('hide');

                            return actions.AddCertificateToDonationSuccess({ });
                        }
                    }),
                    catchError(error => {

                        const alert : AlertContent = {
                            title: 'Atención !!',
                            content: 'Fallo al generar certificados para las donaciones seleccionadas',
                            color:'rgb(252 , 244 , 232)',
                            borderColor : '1px solid rgb(240 , 183 , 117)',
                            icon: 'faTriangleExclamation',
                            iconColor:'rgb(161 , 18 , 18)'
                        }
                    
                        this.componentToAlertComponentService.sendMessage(alert);

                        this.componentToSpinnerService.sendMessage('hide');

                        return of( actions.AddCertificateToDonationFailure({ error : error }) )
                    })
                )
            })
        )
    })

    setAssociatedCertificatedAsSent$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.SetCertificateAsSentInAssociatedDonation),
            mergeMap(( action ) => {

                const { donationsIds } = action || {};

                return this.donationsService.SetDonationAssociatedCerficateAsSent( donationsIds )
                .pipe(
                    map(( setCertificateAsSentResponse ) =>{

                        const alert : AlertContent = {
                            title: 'Atención',
                            content: 'Los certificados para las donaciones seleccionadas se marcaron como enviados',
                            color:'rgb(232 , 252 , 239)',
                            borderColor : '1px solid rgb(34 , 195 , 89)',
                            icon: 'faCircleCheck',
                            iconColor:'rgb(161 , 18 , 18)',
                            navigateTo : 'navigateTo'
                        }

                        this.componentToSpinnerService.sendMessage('hide');
                    
                        this.componentToAlertComponentService.sendMessage(alert);
                        
                        return actions.SetCertificateAsSentInAssociatedDonationSuccess({ setCertificateAsSentResponse , donationsIds });
                    }),
                    catchError(error => {

                        const alert : AlertContent = {
                            title: 'Atención !!',
                            content: 'Algo Falló al marcar los certificados como enviados',
                            color:'rgb(252 , 244 , 232)',
                            borderColor : '1px solid rgb(240 , 183 , 117)',
                            icon: 'faTriangleExclamation',
                            iconColor:'rgb(161 , 18 , 18)'
                        }
                    
                        this.componentToAlertComponentService.sendMessage(alert);

                        this.componentToSpinnerService.sendMessage('hide');

                        return of( actions.SetCertificateAsSentInAssociatedDonationFailure({ error : error }) )
                    })
                )
            })
        )
    })

    setSentByEmailPropertyToDonationCertificate$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.SetSendByEmailPropertyToDonationFile),
            mergeMap(( action ) => {

                const { donationsIds } = action || {};

                return this.donationsService.SendDonationsByEmail( donationsIds )
                .pipe(
                    map(( sendDate ) =>{

                        const alert : AlertContent = {
                            title: 'Atención',
                            content: 'Se han enviado por email los certificados de las donaciones seleccionadas',
                            color:'rgb(232 , 252 , 239)',
                            borderColor : '1px solid rgb(34 , 195 , 89)',
                            icon: 'faCircleCheck',
                            iconColor:'rgb(161 , 18 , 18)',
                            navigateTo : 'navigateTo'
                        }
                    
                        this.componentToAlertComponentService.sendMessage(alert);

                        this.componentToSpinnerService.sendMessage('hide');

                        const dataForReducer : { sendDate : Date , donationsIds : string[] } = { sendDate , donationsIds };
                        
                        return actions.SetSendByEmailPropertyToDonationFileSuccess({ dataForReducer });
                    }),
                    catchError(error => {

                        const alert : AlertContent = {
                            title: 'Atención !!',
                            content: 'Algo Falló al marcar enviar los certificados por email',
                            color:'rgb(252 , 244 , 232)',
                            borderColor : '1px solid rgb(240 , 183 , 117)',
                            icon: 'faTriangleExclamation',
                            iconColor:'rgb(161 , 18 , 18)'
                        }
                    
                        this.componentToAlertComponentService.sendMessage(alert);

                        this.componentToSpinnerService.sendMessage('hide');

                        return of( actions.AddCertificateToDonationFailure({ error : error }) )
                    })
                )
            })
        )
    })

    setAllAssociatedCertificatedAsSent$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.SetAllCertificateAsSentInAssociatedDonation),
            mergeMap(( action ) => {

                const { year } = action || {};

                return this.donationsService.SetAllDonationAssociatedCerficateAsSent( year )
                .pipe(
                    map(( setCertificateAsSentResponse ) =>{

                        const alert : AlertContent = {
                            title: 'Atención',
                            content: 'Se han marcado como enviado todos los certificados',
                            color:'rgb(232 , 252 , 239)',
                            borderColor : '1px solid rgb(34 , 195 , 89)',
                            icon: 'faCircleCheck',
                            iconColor:'rgb(161 , 18 , 18)',
                            navigateTo : 'navigateTo'
                        }
                    
                        this.componentToAlertComponentService.sendMessage(alert);

                        this.componentToSpinnerService.sendMessage('hide');
                        
                        return actions.SetAllCertificateAsSentInAssociatedDonationSuccess({ setCertificateAsSentResponse });
                    }),
                    catchError(error => {

                        const alert : AlertContent = {
                            title: 'Atención !!',
                            content: 'Algo Falló al marcar los certificados como enviados',
                            color:'rgb(252 , 244 , 232)',
                            borderColor : '1px solid rgb(240 , 183 , 117)',
                            icon: 'faTriangleExclamation',
                            iconColor:'rgb(161 , 18 , 18)'
                        }
                    
                        this.componentToAlertComponentService.sendMessage(alert);

                        this.componentToSpinnerService.sendMessage('hide');

                        return of( actions.SetAllCertificateAsSentInAssociatedDonationFailure({ error : error }) )
                    })
                )
            })
        )
    })
}