import { Injectable } from '@angular/core';
import { Donation, DonationToUpdate } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class DonationsMapsService {

  GetDonationToUpdateMapped( originalDonation : Donation ):DonationToUpdate{

    const { 
      accountNumber , address , amount  , city , country , documentNumber , id , associated ,
      email , firstName , lastName , phone , postalCode , prefix , province , status , type ,
      projectId , periodicityType , dateTime
    } = originalDonation || {};

    return {
      id : id , 
      firstName : firstName ,
      lastName : lastName , 
      amount : amount,
      accountNumber : accountNumber ,
      documentNumber : documentNumber ,
      email : email , 
      prefix : prefix,
      phone : phone ,
      address : address ,
      city : city,
      province : province,
      postalCode : postalCode ,
      country : country,
      type : associated ? 0 : 1,
      status : status,
      projectId : type === 1 ? projectId : undefined ,
      periodicityType : periodicityType, 
      dateTime : dateTime
    }
  }
}
