import { createAction, props } from "@ngrx/store"
import { DonationsFilters } from "src/app/models"

export const modifyDonationsStatusfilter = createAction(
    '[Donations Filters] modify donations status filters',
    props<{ donationsStatusFilter : string }>()
)

export const addUserEmailToFilters = createAction(
    '[Donations Filters] add email to filters donations',
    props<{ userName : string | undefined }>()
)

export const modifyDonationsfilter = createAction(
    '[Donations Filters] modify donations filters',
    props<{ donationsFilters : DonationsFilters }>()
)

export const modifySizeDonationFilter = createAction(
    '[Donation Filters] modify size donation filter',
    props<{ size : number }>()
)

export const modifyFirstDonationFilter = createAction(
    '[Donation Filters] modify first donation filter',
    props<{ first : number }>()
)