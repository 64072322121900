import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditLogsHelperService {

  GetAuditLogAction(num : number):string{

    let myAction = "";

    switch (num) {
      case 0: myAction = 'Creado'
        break;
      case 1: myAction = 'Actualizado'
        break;
      case 2: myAction = 'Borrado'
        break;
      case 3: myAction = 'Descargado'
        break;
      case 4: myAction = 'Generado'
        break;
      case 5: myAction = 'Enviado'
        break;
    
      default:
        break;
    }

    return myAction;
  }
}
