import { createReducer, on } from "@ngrx/store";
import { DonationAuditLogsfilters } from "src/app/models";
import * as actions from "src/app/store/actions";

export interface donationAuditLogsFiltersState {
    DonationProjects : DonationAuditLogsfilters;
}

export const initialUserEntries : DonationAuditLogsfilters = new DonationAuditLogsfilters() ;

export const DonationAuditLogsFilterReducer = createReducer(
    initialUserEntries,

    on( actions.AddDonationAuditLogsFilters , (state , { donationAuditLogsfilters }) => {

        state = donationAuditLogsfilters;
        return state;
    }),

    on( actions.modifySizeLogsFilter , (state , {size}) => {

        return { ...state , size : size };
    }),

    on( actions.modifyFirstlogsFilter , (state , { first }) =>{

        return { ...state , first : first };
    })
) 