import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DonationAuditLogResponse, DonationAuditLogsfilters } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuditlogsService {

  constructor(
    private http :                                                 HttpClient
  ) { }

  LoadSingleDonationAuditLogs( donationsAuditsLogsFilters : DonationAuditLogsfilters ):Observable<DonationAuditLogResponse>{

    const { donationId , first , size } = donationsAuditsLogsFilters || {};

    return this.http.get<DonationAuditLogResponse>( `${environment.donationsService.url}auditLog/${donationId}?First=${first}&Size=${size}` )
  }

  LoadAllDonatiosnAuditLogs( donationsAuditsLogsFilters : DonationAuditLogsfilters ):Observable<DonationAuditLogResponse>{

    const { donationId , first , size } = donationsAuditsLogsFilters || {};

    return this.http.get<DonationAuditLogResponse>( `${environment.donationsService.url}auditLog?First=${first}&Size=${size}` )
  }
}
