import { UserInfo } from 'src/app/models';
import { createReducer , on } from '@ngrx/store';
import * as actions from 'src/app/store/actions';

export interface userState {
    User: UserInfo
}

export const initialUserEntries : UserInfo = {} ;

export const UserReducer = createReducer(
    initialUserEntries,

    on(actions.addUser , (state , User)=>{
        state = User;
        return state;
    }),
    
    on(actions.removeUser , (EmptyObject) => {
        const entriesClone : UserInfo = EmptyObject;
        return entriesClone;
    }),

    on( actions.addProfessionalAvatar , ( state , { avatar } ) =>{
        
        return { ...state , avatar : avatar };
    })
) 