import { DonationsForSepaFilters } from 'src/app/models';
import { createReducer , on } from '@ngrx/store';
import * as actions from 'src/app/store/actions';

export interface donationsForSepaFiltersState {
    DonationsForSepaFilters : DonationsForSepaFilters
}

export const initialUserEntries : DonationsForSepaFilters = new DonationsForSepaFilters() ;

export const DonationsForSepaFiltersReducer = createReducer(
    
    initialUserEntries,

    on(actions.StoreDonationsForSepaFilters , (state , {donationsForSepaFilters})=>{

        state = donationsForSepaFilters;

        return state;
    })
) 